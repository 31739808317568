import * as React from "react"
import Seo from "../components/seo"
import Terms from "../components/sections/terms/Terms"
import { Helmet } from "react-helmet"

const TermsPage = () => (
  <>
    <Seo title="Terms and Conditions" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <Terms />
  </>
)

export default TermsPage
